import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Política de privacidad - ExpertSender" description="Privacy policy - grow your business using data-driven multichannel marketing automation and beat the competition!" />
        <Hero title="Política de privacidad" button="false" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                <p><strong>ExpertSender is committed to protecting the privacy of our clients and website visitors. Maintaining it fosters confidence, goodwill and stronger relationships. For more information on how ExpertSender enforces permission email marketing with our clients, please see our Anti-Spam Policy as well as feel free to contact us.</strong></p>
                  <h4>Website Information Collection</h4>
                  <p>Unless otherwise stated on our Website, we collect only the domain name and IP address for each visitor to our Web page, the email addresses of those who communicate with us via email, aggregated and user-specific information on what pages consumers access or visit and information volunteered by the consumer, such as survey information. We also may track browser types to help us understand our visitors’ needs related to our Website design. There is an optional section of our Website where input forms require your name, phone number, company, and email address. We may use personally identifiable information collected this way to: (a) send information to you which we think may be of interest to you by email or other means; (b) send you marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you.</p>
                  <p>We never sell, share or trade any of your personally identifiable information to any third party.</p>
                  <p>We do, however, reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on our Website.</p>
                  <p>You may unsubscribe from our marketing communications by clicking on the “unsubscribe” link located in the footer of our emails, or by sending us email at contact@expertsender.com.</p>
                  <p>Use of this site (ExpertSender’s system) requires personal data processing of a client in the following matter: first name and last name, phone number, e-mail, job position, company). Data will be processed by: ExpertSender (ExpertSender Sp. z o.o.) in a way that is crucial to agreeing on a contract, as well as its fulfillment. Transaction data, in that personal data, can be transferred for the benefit of Tpay (Krajowy Integrator Płatności S.A.) located in Poznań at Św. Marcin 73/6, zip code: 61-808, KRS: 0000412357, on the terms that it will beneficial on in service connected to order payments. A client has the right to see the content of his data and any corrections made to it. Sharing of information is voluntary, however, at the same time it is crucial to using this site.</p>
                  <h4>Your Email Lists and Databases</h4>
                  <p>Your Email Lists are stored on a secure server. We will not sell, rent, loan or invite external access to your lists, nor will ExpertSender use your lists for any other reason than customer support and abuse-monitoring purposes. You should stay assured that your data is kept strictly confidential.</p>
                  <h4>Web Beacons</h4>
                  <p>ExpertSender includes a ‘web beacon’ in most of the HTML email messages sent. A web beacon is in fact a small image in the message’s content, which is activated when an HTML email is opened via a request for the image on our servers.</p>
                  <p>We also provide the capability to track when a URL has been clicked inside of an email message sent by our system. We use this functionality to track which links were clicked and in what quantity, as well as to determine the aggregate number of links clicked. This click tracking functionality does not collect any personally identifiable information.</p>
                  <p>This enables our clients to track the aggregate number of emails opened. The web beacon does not collect any personally identifiable information. Users that do not want to receive email messages with web beacons can choose to receive their emails in text (non-HTML) format.</p>
                  <h4>Cookies</h4>
                  <p>We use cookies to record session information, such as past activity at the site in order to provide better service when visitors return to our site or customize Web page content based on visitors’ browser type.</p>
                  <p>We use Google Analytics and re-targeting cookies to allow 3rd party service providers to provide advertisements on our behalf across the internet. These service providers may collect your IP address and non-personal data about your visits. This is completely anonymous and does not include your name, address, email address or other personal information. Additionally, these cookies are used to gather anonymous statistical information on how the website is browsed. These cookies will expire after 10 years.</p>
                  <p>We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device’s IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf.</p>
                  <h4>Security Technology</h4>
                  <p>ExpertSender operates in secure environment by using the latest server, database, backup and firewall technologies. All data resides in a tightly controlled, secure data center that prevents unauthorized access and data security. This means that information about the identity and preferences of individual members is never accessible to anyone from the outside.</p>
                  <p>However, we cannot guarantee the security of your data while it is being transmitted over the Internet and through servers that are out of our control – that is after you deploy your messages.</p>
                  <h4>Payments</h4>
                  <p>Payments are serviced by <a href="https://tpay.com" target="_blank" rel="nofollow noopener">Tpay.com</a>. Tpay is registered as Krajowy Integrator Płatności S.A. ul. Św. Marcin 73/6 61-808 Poznań NIP: 7773061579 REGON: 300878437 KRS: 0000412357</p>
                  <h4>Complaint procedure</h4>
                  <ol>
                    <li>Complaints related to the provision of Electronic Services may be submitted by the Service Recipient via email to the following address: invoice@expertsender.com</li>
                    <li>In the above email all the circumstances related to the subject of the complaint should be described, including the type and date of irregularities and contact details. The information provided will significantly facilitate and accelerate complaint processing procedure by the Service Provider.</li>
                    <li>All complaints shall be resolved promptly by the Service Provider, no later than within 30 days following the date of receipt.</li>
                  </ol>
                  <h4>Notification of Changes</h4>
                  <p>ExpertSender will notify its members and users via email if we make any changes to our privacy policy. If at any point we decide to use personally identifiable information in a manner different from that stated at the time it was collected, we will notify about this fact. You will have a choice as to whether or not we use your information in this different manner.</p>
                  <p>If we decide to change our Privacy Policy, we will post those changes to this privacy statement, or the home page, and other places we deem appropriate, so that you are aware of what information we collect and how we use it.</p>
                  <p className="mar-b-3">We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our Website.</p>
                  <h3 className="mar-b-3">Information on personal data processing</h3>
                  <p>Below you will find information on the processing of your personal data by ExpertSender Sp. z o. o. pursuant to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (OJ L 119, p. 1) – hereinafter referred to as the GDPR.</p>
                  <h4>Personal Data Controller</h4>
                  <p>Personal Data Controller of your personal data is ExpertSender Sp. z o.o. with its registered office in Gdańsk (postal code: 80-280), ul. Norwida 1, entered into the register of entrepreneurs of the National Court Register, held by a District Court Gdańsk – Północ in Gdańsk, 8th Commercial Division, under a registration number (KRS): 0000329885, Tax Identification Number (NIP): 5862237116, statistical No. (REGON): 220775305, with a share capital of PLN 50 000.</p>
                  <p>You may contact us by:</p>
                  <ul>
                    <li>e-mail at the address: contact@expertsender.com</li>
                    <li>the electronic contact form available at expertsender.com</li>
                  </ul>
                  <h4>Contact data of the Data Protection Officer</h4>
                  <p>We have appointed a person responsible for the protection of personal data – Data Protection Officer (Michał Kidoń), whom you may contact under this e-mail address: dpo@expertsender.com</p>
                  <h4>Purposes of data processing, legal basis and legitimate interests of ExpertSender</h4>
                  <p>Your personal data are processed <strong>for the following purposes:</strong></p>
                  <ul>
                    <li>Sending you our e-mail newsletter consisting updates on our products, services, news and events. – the legal basis for the processing is your voluntarily given consent (Article 6 (1a) of the GDPR);</li>
                    <li>For the establishment, exercise or defense of legal claims connected to the purpose of sending you our e-mail newsletter or with the processing of your personal data – given that such processing is necessary to pursue our legitimate interest (Article 6 (1f) of the GDPR) which consists in the possibility of the establishment, exercise or defense of legal claims;</li>
                    <li>To fulfill our legal obligations– given that in such a case processing is necessary for compliance with legal obligations which we are subject to (Article 6 (1c) of the GDPR).</li>
                  </ul>
                  <h4>Categories of data recipients</h4>
                  <p>Recipients of your personal data may include entities representing the following categories:</p>
                  <ul>
                    <li>entitites authorised to receive personal data on the grounds of binding law provisions;</li>
                    <li>entities rendering the following services: printing and archiving services, marketing, communication and analytical entities, IT and new technologies, electronical services, legal services.</li>
                  </ul>
                  <h4>Transferring data outside the European Economic Area</h4>
                  <p>We commission performing individual services and operation to selected service providers from the IT sector whose seat is located outside the European Economic Area – in a third country. In such cases, data are transferred to a third country pursuant to the decision of the European Commission confirming adequate level of data protection (Article 45 of the GDPR), and in the case of lack of such a decision proper safeguards are applied in order to ensure proper level of data protection – these include in particular standard EU clauses (Article 46 of the GDPR) and binding corporate rules (Article 47 of the GDPR). At any time, you may request further information and receive copies of proper safeguards.</p>
                  <h4>Data storage period</h4>
                  <p>Your personal data will be processed for different periods, depending on the purpose of the processing:</p>
                  <ul>
                    <li>For the purpose of sending you our e-mail newsletter – always until the moment of withdrawing your consent;</li>
                    <li>For the purpose of establishment, exercise or defense of claims – until the end of statute of limitations for claims or until your objection has been acknowledged;</li>
                    <li>For the purpose of fulfilling legal obligations which we are subject to – until expiry of the obligations regarding the storage of data pursuant to legal provisions.</li>
                  </ul>
                  <h4>Your rights</h4>
                  <p>You have the right to:</p>
                  <ul>
                    <li>Access the content of your personal data, rectify (correct, supplement) them, limit their processing, transfer them or have them deleted;</li>
                    <li>Withdraw the consent you gave at any moment without affecting the compliance with law of the processing that had been performed pursuant to a consent before its withdrawal;</li>
                    <li>To lodge a complaint to the supervisory authority when you conclude that the processing of your personal data is in breach with the GDPR.</li>
                  </ul>
                  <h4>Right to object</h4>
                  <p><strong>Irrespective of the reason</strong>, you have the right to make, at any moment, <strong>an objection to the processing of your personal data</strong> which is necessary to pursue our legitimate interest.</p>
                  <h4>Information on whether one is required to provide data or it is voluntary and on the consequences of non-provision of data</h4>
                  <p>Providing personal data in connection with consent given for processing of personal data is always of a voluntary nature; failing to provide them (failing to give a consent or withdrawing it) does not give rise to any consequences.</p>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
